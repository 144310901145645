/* ==========================================================================
   #BLOCKS
   ========================================================================== */

.Block {
  width: 100%;
  margin-top: 60px;
}

.Block-title {
  margin-top: 40px;
  color: var(--color-typo1);
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
}
