/* ==========================================================================
   #AVATAR
   ========================================================================== */

.Avatar {
  display: inline-block;
  vertical-align: middle;

  img {
    border-radius: 51px;
  }
}

.Avatar--home {
  margin-bottom: 40px;
}
