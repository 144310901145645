/* ==========================================================================
   #ICON
   ========================================================================== */

.Icon {
  > svg {
    display: inline-block;
    width: 24px;
    height: 24px;
    vertical-align: middle;
  }
}

$icon-sizes: (12, 16, 24, 36);

@each $size in $icon-sizes {
  .Icon--s#{$size} {
    > svg {
      height: #{$size}px;
    }
  }
}

.Icon--link {
  &:hover {
    .Icon-path {
      fill: var(--color-grey1);
    }
  }
}

.Icon-path {
  transition: fill 0.2s;
  fill: var(--color-grey2);
}

.Icon--cTwitter {
  .Icon-path {
    fill: var(--color-twitter);
  }
}
