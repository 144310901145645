/* ==========================================================================
   #POSTS
   ========================================================================== */

.Posts-item {
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }
}

.Posts-img {
  display: block;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  border-radius: 3px;
  background: var(--color-grey4);

  &:hover {
    img {
      transform: scale(1.02);
    }
  }

  @media (--size-s) {
    margin-bottom: 20px;
  }

  img {
    transition: transform 0.2s ease-out;
  }
}

.Post-title {
  margin-bottom: 5px;
  font-size: 24px;
  line-height: 30px;

  @media (--size-m) {
    font-size: 18px;
    line-height: 22px;
  }
}
