/* ==========================================================================
   #FOOTNOTES
   ========================================================================== */

.Footnote {
  display: inline-block;
  position: relative;
  top: -6px;
  width: 14px;
  height: 14px;
  border-radius: 2px;
  outline: none;
  background: var(--color-grey1);
  color: #fff;
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;

  &:hover {
    color: #fff;
  }
}

.Footnotes-item {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.Marginote {
  position: absolute;
  right: 0;
  width: 300px;
  margin-right: -320px;
  padding-left: 20px;
  transition: opacity 0.2s;
  border-left: 1px solid var(--color-grey3);
  opacity: 0;
  font-size: 14px;

  &.is-active {
    opacity: 1;
  }

  .Footnote-return {
    display: none;
  }
}
