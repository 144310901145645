/* ==========================================================================
   #HEADER
   ========================================================================== */

.Header {
  position: relative;
  height: 500px;

  @media (--size-m) {
    height: 300px;
  }
}

.Header-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.Header-background--cover {
  background-position: center;
  background-size: cover;
}

.Header-background--black {
  background: rgba(#000, 0.2);
}

.Header-icon {
  position: absolute;
  top: 20px;
  transition: all 0.2s;
  opacity: 0.6;

  &:hover {
    opacity: 0.8;
  }
}

.Header-icon--back {
  left: 20px;
  transform: translateX(0);

  &:hover {
    transform: translateX(-2px);
  }
}

.Header-icon--share {
  right: 20px;
}

.Header-footer {
  position: absolute;
  bottom: 20px;
  width: 100%;
  color: #fff;
}
