/* ==========================================================================
   #FOOTER
   ========================================================================== */

.Footer {
  padding-top: 40px;
  padding-bottom: 40px;

  @media (--size-m) {
    text-align: center;
  }
}

.Footer-description {
  display: inline-block;
  width: calc(100% - 105px);
  margin-bottom: 0;
  padding-left: 40px;
  vertical-align: middle;

  @media (--size-m) {
    width: 100%;
    margin-top: 20px;
    padding-left: 0;
  }
}

.Footer-credits {
  color: var(--color-typo3);
  text-align: center;
}
