/* ==========================================================================
   #BUTTONS
   ========================================================================== */

.Button {
  display: inline-block;
  transition: all 0.2s;
  border: 1px solid var(--color-grey2);
  border-radius: 3px;
  outline: none;
  color: var(--color-typo3);
  font-size: var(--font-size-base);
  line-height: 1;
  text-align: center;
  white-space: nowrap;

  &:hover {
    border-color: var(--color-grey2--hover);
    color: var(--color-typo3--hover);
    text-decoration: none;
  }

  > span {
    display: inline-block;
  }
}

.Button--m {
  > span {
    padding: 12px 24px;
  }
}
