/* ==========================================================================
   #BLOCKQUOTE
   ========================================================================== */

.Pullquote {
  margin: 40px;
  font-size: 21px;
  line-height: 30px;
  text-align: center;

  @media (--size-m) {
    margin: 40px 20px;
  }

  span {
    position: relative;
    color: var(--color-grey3);
    font-size: 31px;
    font-weight: 700;
    line-height: 0;

    &:first-child {
      left: -5px;
    }

    &:last-child {
      right: -5px;
    }
  }
}

.Blockquote {
  padding-bottom: 3px;
  padding-left: 20px;
  border-left: 3px solid var(--color-grey3);
  font-style: italic;
}
