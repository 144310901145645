/* ==========================================================================
   #TITLES
   ========================================================================== */

/* Colors
   ========================================================================== */

.Title--cTypo1 {
  color: var(--color-typo1);
}

.Title--cTypo3 {
  color: var(--color-typo3);
}

.Title--cWhite {
  color: #fff;
}

/* Sizes
   ========================================================================== */

.Title {
  font-weight: 400;
}

.Title--h1 {
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;

  @media (--size-m) {
    font-size: 30px;
    line-height: 36px;
  }
}

.Title--h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
}

.Title--h3 {
  font-size: 18px;
  line-height: 22px;
}
