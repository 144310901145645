/* ==========================================================================
   #NAVBAR
   ========================================================================== */

.Navbar {
  height: 80px;
  padding-right: 20px;
  padding-left: 20px;
}

.Navbar-menu {
  margin-left: 0;
  text-align: center;
}

.Navbar-item {
  display: inline-block;
  margin-right: 40px;

  &:last-child {
    margin-right: 0;
  }

  @media (--size-m) {
    margin-right: 20px;
  }
}

.Navbar-link {
  transition: color 0.2s;
  color: var(--color-typo3);
  line-height: 80px;

  &:hover {
    color: var(--color-typo3--hover);
  }
}
