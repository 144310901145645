/* ==========================================================================
   #CONTENT
   ========================================================================== */

.Offcanvas {
  overflow: hidden;
}

.Content {
  position: relative;
  margin-right: auto;
  margin-left: auto;
}

.Content--cGrey {
  background: var(--color-grey4);
}

.Content-inner {
  padding-top: 60px;
  padding-bottom: 60px;
}

.Content-inner--post {
  margin-right: 25%;
  margin-left: 25%;

  @media (--size-xl) {
    margin-right: auto;
    margin-left: auto;
  }
}

.Content-body {
  position: relative;

  p {
    + .Slideshow--right {
      margin-top: 20px;
    }
  }

  ul,
  ol {
    margin-left: 20px;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  li {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    > p,
    > ul,
    > ol {
      margin-top: 10px;
      margin-bottom: 0;

      + p {
        margin-top: 10px;
      }
    }
  }
}

.Content-header {
  margin-bottom: 40px;
}

.Content-title {
  margin-top: 40px;
}

.Content-title--h1 {
  margin-top: 0;
  margin-bottom: 10px;
  animation-delay: calc(var(--move-in-base-delay) * 5);
}

.Content-title--h2 {
  margin-bottom: 10px;

  + .Content-title--h3 {
    margin-top: 10px;
  }

  + p {
    margin-top: 20px;
  }
}

.Content-subtitle {
  color: var(--color-typo3);
  font-size: 21px;
  line-height: 25px;

  a {
    font-weight: 400;
    text-decoration: none;
  }
}

.Content-date {
  display: block;
  color: var(--color-typo3);
  font-size: 15px;
  font-weight: 400;
  text-transform: uppercase;
}

.Content-intro {
  font-size: 18px;
  line-height: 31.5px;
}
