/* ==========================================================================
   #ANIMATIONS
   ========================================================================== */

:root {
  --move-in-animation: 1s both move-in;
  --move-in-base-delay: 100ms;
  --move-in-offset: 10px;
}

.animate {
  animation: var(--move-in-animation);
}

@for $i from 4 through 9 {
  .animate--d#{$i} {
    animation-delay: calc(var(--move-in-base-delay) * #{$i});
  }
}

@keyframes move-in {
  from {
    transform: translateY(var(--move-in-offset));
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}
