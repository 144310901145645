/* ==========================================================================
   #MAP
   ========================================================================== */

.Map {
  position: absolute;
  top: 0;
  bottom: auto;
  width: 320px;
  transform: translate3d(-320px, 20px, 0);

  &.is-fixed {
    position: fixed;
  }

  &.is-bottom {
    top: auto;
    bottom: 0;
  }

  @media (--size-xxl) {
    display: none;
  }
}

.Country {
  fill: var(--color-grey4);
}

.Route {
  stroke-linecap: round;
  stroke-dasharray: 2, 2;
  stroke: var(--color-grey1);
  fill: none;
  stroke-linejoin: round;

  &.is-hidden {
    display: none;
  }
}

.Route--story {
  display: none;
  stroke-width: 3px;
  stroke: var(--color-gold);
  stroke-opacity: 0.6;

  &.is-active {
    display: block;
  }
}

.City {
  fill: var(--color-typo3);

  &.is-active {
    fill: var(--color-typo1);
  }
}

.City-label {
  fill: var(--color-typo3);
  font-size: 10px;
  text-shadow: 0 1px 0 #fff, 0 -1px 0 #fff, 1px 0 0 #fff, -1px 0 0 #fff;
  cursor: pointer;

  &.is-visited {
    fill: var(--color-typo1);
  }

  &.is-active {
    fill: var(--color-typo1);
    font-weight: 600;
  }
}
