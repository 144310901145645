/* ==========================================================================
   #STORY
   ========================================================================== */

.Story {
  display: block;
  position: relative;
  margin-bottom: 40px;
  overflow: hidden;
  background: var(--color-grey4);

  &:hover {
    .Story-gradient {
      z-index: 20;
      background: rgba(#000, 0);
    }

    .Story-inner {
      transform: scale(1.05);
      opacity: 0;
    }

    .Story-img {
      transform: scale(1.01);
    }
  }
}

.Story-inner {
  z-index: 30;
  transition: all 0.2s ease-in-out;
  opacity: 1;
}

.Story-inner,
.Story-gradient {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.Story-gradient {
  z-index: 20;
  transition: all 0.2s ease-in-out;
  background: rgba(#000, 0.2);
}

.Story-header {
  text-align: center;
}

.Story-title,
.Story-description {
  text-shadow: 0 2px 0 rgba(#000, 0.3);
}

.Story-title {
  margin-bottom: 10px;
  color: #fff;
  font-size: 21px;
  font-weight: 700;
  line-height: 25px;
}

.Story-description {
  color: rgba(#fff, 0.6);
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  text-transform: uppercase;
}

.Story-img {
  z-index: 10;
  transition: transform 0.2s ease-out;
}
