/* ==========================================================================
   #ASIDE
   ========================================================================== */

.Aside {
  iframe {
    display: block;
  }
}

.Aside--right {
  width: 160px;
  margin: 10px 0 10px 40px;
  float: right;
}

.Aside--left {
  width: 400px;
  margin: 10px 40px 10px -240px;
  float: left;
}

.Aside--right,
.Aside--left {
  @media (--size-xl) {
    width: var(--size-s);
    margin-top: 40px;
    margin-bottom: 40px;
    margin-left: 0;
    float: none;
  }

  @media (--size-s) {
    width: 100%;
  }
}

.Aside-media {
  width: 400px;
  max-width: none;

  @media (--size-xl) {
    width: 100%;
  }
}

.Caption {
  margin-bottom: 0;
  padding: 10px 0;
  border-bottom: 1px solid var(--color-grey3);
  color: var(--color-typo3);
  font-size: 13px;
}

.Caption--aside {
  width: 400px;

  @media (--size-xl) {
    width: 100%;
  }
}
