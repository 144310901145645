/* ==========================================================================
   #TYPOGRAPHY
   ========================================================================== */

:root {
  --font-weight-base: 400;
  --font-size-base: 15px;
  --line-height-base: 26.25px;
  --font-family-base: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Helvetica, Arial, sans-serif;
}
