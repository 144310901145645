* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  background-color: #fff;
  color: var(--color-typo2);
  font-family: var(--font-family-base);
  font-size: var(--font-size-base);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: var(--font-weight-base);
  line-height: var(--line-height-base);
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
ul,
ol,
dl,
figure {
  margin-bottom: 20px;
}

p,
ul,
ol {
  &:last-child {
    margin-bottom: 0;
  }
}

a {
  transition: color 0.2s;
  color: var(--color-red);

  &:hover {
    color: var(--color-red--hover);
  }
}

strong {
  font-weight: 600;
}

em {
  font-style: italic;
}

abbr {
  border-bottom: 1px dotted #ddd;
}

button {
  margin: 0;
  border: 0;
  outline: none;
  font-family: var(--font-family-base);
  cursor: pointer;
}

img,
svg {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

sup {
  top: -0.5em;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

pre,
code {
  border: 1px solid var(--color-grey2);
  border-radius: 3px;
  background-color: var(--color-grey4);
  font-family: 'PT Mono', monospace;
  font-size: 13px;
}

code {
  padding: 1px 5px;
}

pre {
  padding: 16px;
  overflow-x: auto;
  line-height: 19px;
  white-space: pre-line;

  > code {
    padding-right: 0;
    padding-left: 0;
    border: 0;
  }
}
