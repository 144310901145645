/* ==========================================================================
   #STATS
   ========================================================================== */

.Stats {
  text-align: right;
}

.Stats-item {
  display: inline-block;
  margin-left: 40px;
  text-align: left;
  text-shadow: 0 2px 0 rgba(#000, 0.3);

  &:first-child {
    margin-left: 0;
  }
}

.Stats-number {
  display: block;
  margin-bottom: 10px;
  font-size: 36px;
  font-weight: 300;

  @media (--size-m) {
    font-size: 30px;
    line-height: 36px;
  }
}
