/* ==========================================================================
   #SEPARATOR
   ========================================================================== */

.Sep {
  display: block;
  width: 30px;
  height: 2px;
  background: var(--color-grey3);
}

.Sep--center {
  margin-right: auto;
  margin-left: auto;
}

.Sep--content {
  margin-top: 60px;
  margin-bottom: 60px;
}
