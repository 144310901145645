/* ==========================================================================
   #CARD
   ========================================================================== */

.Card-title {
  margin-bottom: 10px;
  transition: color 0.2s;
}

.Card-link {
  color: var(--color-typo1);
  font-weight: 700;
  text-decoration: none;

  &:hover {
    color: var(--color-typo1--hover);
  }
}

.Card-subtitle,
.Card-description {
  font-weight: 400;
}

.Card-subtitle {
  margin-bottom: 5px;
  color: var(--color-typo3);
  font-size: 13px;
  text-transform: uppercase;
}

.Card-description {
  color: var(--color-typo2);
}
