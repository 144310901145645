/* ==========================================================================
   #SOCIAL
   ========================================================================== */

.Social-item {
  display: inline-block;
  margin-left: 20px;

  &:first-child {
    margin-left: 0;
  }
}
