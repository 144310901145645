/* ==========================================================================
   #UTILITIES
   ========================================================================== */

.u-inner {
  @include clearfix;

  position: relative;
  z-index: 1;
  width: 100%;
  max-width: var(--size-m);
  margin-right: auto;
  margin-left: auto;
  padding-right: var(--gap);
  padding-left: var(--gap);
}

.u-inner--l {
  max-width: var(--size-l);
}

.u-inner--xl {
  max-width: var(--size-xl);
}

.u-inner--xxl {
  max-width: var(--size-xxl);
}

.u-strike {
  text-decoration: line-through;
}

.u-hEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.u-vEllipsis {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.u-vEllipsis--lin3 {
  -webkit-line-clamp: 3;
}

.u-txt-center {
  text-align: center;
}

.u-hide {
  display: none;
}

.u-show {
  display: block;
}

.ratio-box {
  display: block;
  position: relative;
  width: 100%;
  height: 0;

  img,
  iframe,
  video {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  animation-name: fade;
  animation-duration: 1s;
}

@keyframes fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@each $viewport, $breakpoint in $viewports {
  .r-#{$viewport}-u-hide {
    display: block;

    @media (--#{$viewport}) {
      display: none;
    }
  }

  .r-#{$viewport}-u-show {
    display: none;

    @media (--#{$viewport}) {
      display: block;
    }
  }
}
