/* ==========================================================================
   #CLEARFIX
   ========================================================================== */

@mixin clearfix {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}
