/* ==========================================================================
   #SLIDESHOW
   ========================================================================== */

.Slideshow {
  width: calc(100% + 240px);
  margin-top: 40px;
  margin-bottom: 40px;

  @media (--size-xl) {
    width: 100%;
  }

  iframe,
  img {
    max-width: 100%;
  }
}

.Slideshow--right {
  float: right;
}

.Slideshow--center,
.Slideshow--wide {
  iframe,
  img {
    border-radius: 3px;
  }
}

.Slideshow--center {
  width: calc(var(--size-l) - 40px);
  margin-left: calc(-1 * (var(--size-l) - var(--size-m)) / 2);

  @media (--size-l) {
    width: 100%;
    margin-left: 0;
  }
}

.Slideshow--wide {
  width: calc(var(--size-xxl) - 40px);
  margin-left: calc(-1 * (var(--size-xxl) - var(--size-m)) / 2);

  @media (--size-xxl) {
    width: 100%;
    margin-left: 0;
  }
}

.Slideshow-social {
  display: block;
  width: 24px;
  height: 24px;
  float: right;
  border: 1px solid var(--color-twitter);
  border-radius: 50%;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  vertical-align: middle;
}
